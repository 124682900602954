<template>
    <div>
      <h1>404 - Page non trouvée</h1>
      <p>La page que vous cherchez n'existe pas.</p>
    </div>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  h1 {
    color: red;
  }
  </style>
  